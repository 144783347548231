import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { userURL, baseurl,ImgUpUrl, userDetailsURL, CheckoutURL } from "../../../config";
import { ReactSession } from 'react-client-session';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Swal from 'sweetalert2';

import LoadPreviewImage from '../../../config/LoadPreviewImage';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

const ShoppingCartArea = () => {


   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   const sessionUserId = ReactSession.get("userid");
   const sessionToken = ReactSession.get("token");
   const [getProductDetails, setGetProductDetails] = useState([]);

   const [getProductQty, setGetProductQty] = useState();
   const [getProductValue, setGetProductValue] = useState();
   const [getProductId, setGetProductId] = useState();
   const [getById, setGetById] = useState();
   const [getPrescriptionProductData, setPrescriptionProductData] = useState([]);

   const [prescription, setPrescription] = useState([]);
   const [prescriptionPath, setPrescriptionpath] = useState();
   const [checkPrescription, setCheckPrescription] = useState(false);
   const [userCartId, setUserCartId] = useState();

   const [userDetailsIs, setUserDetailsIs] = useState(false);

   const [prescriptionErrorshow, setPrescriptionErrorshow] = useState(false);
   const [totalCost, setTotalCost] = useState(0);


   useEffect(() => {

      showAllCartProduct();
      //getPrescriptionDatas();

      showUserDetails();

   }, [])

   const showAllCartProduct = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/showCartDetails`, productDetail, authToken)
         .then(data => {
            setGetProductDetails(data.data.data);
            console.log('data.data.datadata.data.data',data.data.data)
            setUserCartId(data.data.data[0]._id);
            getPrescriptionDatas(data.data.data[0]._id);
            if (data.data.data[0].productqty < data.data.data[0].productid.qtyLimit) {

            }
            // for (i = 0; i < data.data.data.length; i++) {
            //    if(data.data.data[i].productid.prescription){
            //       setPrescriptionProductData(data.data.data[i].productid.product);
            //       console.log('fdfdd',data.data.data[i].productid.product);
            //    }
            // }
         })
         .catch(error => {

         });

   }

   const cartRemoveDetails = selectProductId => {

      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId,
         productid: selectProductId
      };

      axios
         .post(`${userDetailsURL}/userDetails/deleteCart`, productDetail, authToken)
         .then(data => {
            ReactSession.set(`addToCartProduct${selectProductId}`, '');
            showAllCartProduct();
            window.location.reload(false);
         })
         .catch(error => {

         });

   }

   const changeQuantity = (id1, id, mrp, qty) => {
      setGetById(id1);
      setGetProductQty(qty);
      setGetProductValue(mrp);
      setGetProductId(id);
      setShow(true);
   };

   const acceptProductQty = event => {
      const result = event.target.value.replace(/\D/g, '');
      setGetProductQty(result);
   }

   const saveQuantity = () => {

      var productIndex = getProductDetails.findIndex(p => p.productid._id == getProductId);
      if (getProductDetails[productIndex].productid.qtyLimit > getProductQty) {
         Swal.fire('Oops...', `This product's minimum quality is : ${getProductDetails[productIndex].productid.qtyLimit}`, 'warning');
         return;
      }

      var productQtyValue = getProductQty;
      var productTotalValue = getProductValue * productQtyValue;
      var productIdValue = getProductId;

      productTotalValue = productTotalValue.toFixed(2);

      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         id: sessionUserId,
         productqty: productQtyValue,
         productTotal: productTotalValue,
         productId: productIdValue,
         id2: getById
      };

      axios
         .post(`${CheckoutURL}/checkout/upateCart`, productDetail, authToken)
         .then(data => {
            showAllCartProduct();
            setShow(false);
         })
         .catch(error => {

         });



   }

   useEffect(() => {

      if (getProductDetails.length > 0) {
console.log(getProductDetails,'getProductDetailsgetProductDetailsgetProductDetailsgetProductDetails')
         var getTotalValue = 0;
         
         for (var i = 0; i < getProductDetails.length; i++) {
            getTotalValue = getTotalValue + getProductDetails[i].productid.rate * getProductDetails[i].productqty;
         }
         
         setTotalCost(getTotalValue);

         for (var j = 0; j < getProductDetails.length; j++) {
          
            if (getProductDetails[j].productid.prescription) {
               setCheckPrescription(true);
               break;
            }
            else {
               setCheckPrescription(false);
            }

         }

      }

   }, [getProductDetails])

   const getPrescriptionDatas = id => {
      const productDetail = {
         cartid: id
      };

      axios
         .post(`${CheckoutURL}/checkout/selectPrescription`, productDetail)
         .then(data => {
            //setPrescriptionpath(data.data.data[0].prescription)
            if (data.data.data[0].prescription) {
               setPrescriptionpath(`${baseurl}/uploadFiles/prescription/${data.data.data[0].prescription}`);
            }
         })
         .catch(error => {

         });

   }

   const getFormPrescription = event => {

      var result = event.target.files[0];
      setPrescription(result);

      var reader = new FileReader();

      reader.onload = function (e) {
         setPrescriptionpath(e.target.result);
      };

      reader.readAsDataURL(result);

      var userId = sessionUserId;
      var cartId = userCartId;

      const configValue = {
         headers: { 'content-type': 'multipart/form-data' },
      };

      const formData = new FormData();
      formData.append('userid', userId);
      formData.append('cartid', cartId);
      formData.append('application', 'web');
      formData.append('prescriptionImg', event.target.files[0]);

      axios
         .post(`${CheckoutURL}/checkout/upatePrescription`, formData, configValue)
         .then(data => {
            if (data.status === 200) {

            } else {

            }
         })
         .catch(error => { });

   };

   const prescriptionMsg = () => {
      // alert("Please Fill Prescription");
      setPrescriptionErrorshow(true);

   }

   const procedureToCheckout = () => {
      if (userCartId) {
         if (userDetailsIs) {

            if (sessionToken) {

               window.location.href = "/checkout/new/page";

            }
            else {

               Swal.fire('Oops..', 'Please Login Your Account', 'error')
                  .then((result) => {
                     if (result) {
                        window.location.href = "/login";
                     } else {

                     }
                  });

            }

         }
         else {

            Swal.fire('Oops..', 'Before Purchase your Products, Please Update your Profile Details', 'info')
               .then((result) => {
                  if (result) {
                     window.location.href = "/profileDetails";
                  } else {

                  }
               });

         }
      }
      else {

         Swal.fire('Oops..', 'Your Shopping Cart is Empty..', 'error')
            .then((result) => {
               if (result) {
                  <Link to={'/shoppingCart'}></Link>
               } else {

               }
            });
      }

   }

   const showUserDetails = () => {
      const authToken = {
         headers: { Authorization: `Bearer ${sessionToken}` }
      };

      const productDetail = {
         userid: sessionUserId
      };

      axios
         .post(`${userDetailsURL}/userDetails/selectUserDetails`, productDetail, authToken)
         .then(data => {
            if (data.data.success) {
               setUserDetailsIs(true);
            }
         })
         .catch(error => {

         });

   }

   return (
      <>

         <Modal size="sm" show={show} backdrop="static" onHide={handleClose}
            aria-labelledby="example-modal-sizes-title-sm">
            <Modal.Header>
               <Modal.Title>Quantity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                     <Form.Label>Change Quantity</Form.Label>
                     <Form.Control
                        type="text"
                        placeholder="Quantity"
                        autoFocus
                        value={getProductQty}
                        onChange={acceptProductQty}
                     />
                  </Form.Group>
               </Form>
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={handleClose}>
                  Close
               </Button>
               <Button variant="danger" onClick={saveQuantity}>
                  Save
               </Button>
            </Modal.Footer>
         </Modal>

         <div className="axil-breadcrumb-area">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-lg-6 col-md-8">
                     <div className="inner">
                        <ul className="axil-breadcrumb">
                           <li className="axil-breadcrumb-item"><a href="/">Home</a></li>
                           <li className="separator"></li>
                           <li className="axil-breadcrumb-item active" aria-current="page">Cart Details</li>
                        </ul>
                        <h1 className="title">Cart Detail </h1>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <section className="cart-area pt-120 pb-120">
            <div className="container">
               <div className="row" style={{ padding: '50px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '25px' }}>
                  <div className="col-12">
                     <form action="#">
                        <div className="table-content table-responsive">
                           <table className="table">
                              <thead>
                                 <tr>
                                    <th className="product-thumbnail">Image</th>
                                    <th className="cart-product-name">Product</th>
                                    <th className="product-subtotal">Rate</th>
                                    <th className="product-quantity">Quantity</th>
                                    <th className="product-subtotal">Total</th>
                                    <th className="product-remove">Remove</th>
                                 </tr>
                              </thead>
                              <tbody className='border-0'>

                                 {getProductDetails.map((options, index) => {

                                    //getProductQty = options.productqty;
                                    //getProductTotal = options.productid.mrp*options.productqty;

                                    return <><tr>
                                       <td className="product-thumbnail">
                                          <Link to={`/onlinePharmacyView/${options.productid._id}`}>
                                             <LoadPreviewImage imagePath={'product'} image={options.productid.image1} style={{ height: '90px', width: '90px' }} />
                                             {/* <img src={baseurl + '/uploadFiles/product/' + options.productid.image1} alt="" style={{ width: '60px' }} /> */}
                                          </Link></td>
                                       <td className="product-name">{
                                          options.productid.prescription == true ?
                                             (
                                                <>
                                                   {options.productid.product}
                                                   {prescriptionPath ?
                                                      <p style={{ color: '#32cd32' }}>Prescription Uploaded</p>
                                                      :
                                                      <p style={{ color: '#ff0000' }}>Prescription Required</p>
                                                   }

                                                </>
                                             )
                                             :
                                             (
                                                <>
                                                   {options.productid.product}
                                                </>
                                             )
                                       }

                                       </td>
                                       <td className="product-subtotal"><span className="amount">Rs. {options.productid.rate}
                                       </span></td>
                                       <td className="product-quantity">
                                          <div className="cart-plus-minus"><input type="text" value={options.productqty} onClick={() => changeQuantity(options._id, options.productid._id, options.productid.rate, options.productqty)} /></div>
                                       </td>
                                       <td className="product-quantity"><div className="cart-plus-minus">Rs. <input type="text" value={parseInt(options.productid.rate) * parseInt(options.productqty)} disabled /></div></td>
                                       <td className="product-remove"><a style={{ cursor: "pointer" }} onClick={() => cartRemoveDetails(options.productid._id)}><i className="fa fa-times"></i></a></td>
                                    </tr></>

                                 })}

                              </tbody>
                           </table>
                        </div>

                        {checkPrescription ?

                           <div className="row">
                              <div className="col-12">
                                 <div className="coupon-all">
                                    <div className="coupon">

                                    </div>

                                    <div className="coupon2" style={{ border: "3px solid #eaedff", padding: "15px" }}>
                                       <h5>Prescription (*)</h5>
                                       <p>Upload JPEG Or PNG Format</p>
                                       <p>Click to view <a href="../../../../../../../img/sample/Prescription.jpg" target="_blank">Sample Prescription</a> </p>
                                       <input name="update_cart" type="file" accept="image/png, image/jpg, image/jpeg" onChange={getFormPrescription} />
                                       <img src={prescriptionPath} id="imagepath3" style={{ width: '150px', height: '150px' }} />
                                    </div>

                                 </div>

                              </div>
                           </div>

                           :
                           null}

                        {checkPrescription ?

                           <div className="row">
                              <div className="col-md-5 ms-auto">
                                 <div className="cart-page-total">
                                    <h2>Cart totalss</h2>
                                    <ul className="mb-20">
                                       <li>Subtotal <span>Rs. {totalCost}</span></li>
                                       <li>Total <span>Rs.{totalCost}</span></li>
                                    </ul>

                                    {prescriptionPath ?
                                       <button type="button" onClick={procedureToCheckout} className="primary_btn theme-btn">Proceed to checkout</button>
                                       :
                                       <button className="primary_btn theme-btn" type="button" onClick={prescriptionMsg}>Proceed to checkout</button>
                                    }

                                    <Toast onClose={() => setPrescriptionErrorshow(false)} show={prescriptionErrorshow} delay={3000} autohide>

                                       <Toast.Body><h6 style={{ color: 'red' }}>Please Upload Prescription for mentioned Medicines</h6></Toast.Body>
                                    </Toast>

                                 </div>
                              </div>
                           </div>

                           :
                           <div className="row">
                              <div className="col-md-5 ms-auto">
                                 <div className="cart-page-total">
                                    <h2>Cart totals</h2>
                                    <ul className="mb-20">
                                       <li>Subtotal <span>Rs. {totalCost.toFixed(2)}</span></li>
                                       <li>Totalsss <span>Rs.{totalCost.toFixed(2)}</span></li>
                                    </ul>

                                    <button type="button" onClick={procedureToCheckout} className="primary_btn theme-btn">Proceed to checkout</button>

                                 </div>
                              </div>
                           </div>}



                     </form>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default ShoppingCartArea;